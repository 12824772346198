import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { AcceptContract, EditUserProfile, InitiateSubscriptionAcceptancePayment, RegisterUser, RejectContract, RespondToTrainerRequest, SearchGoogleMapsAutocomplete, SearchGoogleMapsPlaces, ValidateUser, ViewContractDetail } from '../../Services/Actions';
import moment from 'moment';
import queryString from 'query-string';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';

import { ReturnUrl_SubscriptionSigning } from '../../Config';

import { BackNextButtons, ContractParties, DetailText, FakeCheckmarkBox, FullNameText, HealthDeclarationAnswerContainer, HealthDeclarationAnswerContainerAdditionalInfo, HealthDeclarationContainer, HealthDeclarationOptionsContainer, HealthDeclarationsContainer, HealthDeclarationTextContainer, InvoicePreviewAmountContainer, InvoicePreviewContainer, InvoicePreviewDateContainer, InvoicePreviewSubContainer, OnTimeContainer, PaidStatusContainer, ProductDescriptionContainer, ProductDescriptionsContainer, ProductsListBlockContainer, ProductsListBlockRowContainer, ProductsListBlocksContainer, ProductsListTableContainer, ProductsListTableRowContainer, StyledAdditionalInfoTextArea, StyledArrowIcon, StyledHR, StyledPaymentNumberPageText, StyledInput, TermsConditionsContainer } from './styles';
import { QuestionContentContainer, QuestionCount, QuestionCountContainer, QuestionDotsContainer } from './styles';
import { CheckingUserNameContainer, RegistrationContainer, StyledAccountInput, StyledSexButtonGroups, UserDetailsContainer, UserDetailsItemContainer } from './styles';
import { NameContainer, PersonalDetailsContainer, StyledProfilePhoto, TrainerRequestContainer, TrainerRequestsContainer } from './styles';
import { TrainerOrganizationContainer, TrainerOrganizationsContainer } from './styles';
import { CircleDiv, ShowPasswordsContainer } from './styles';

import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import LanguageOptions from './Languages';
import Loading from '../../Components/Loading';
import Page from '../../Components/Page';
import MultiText from '../../Components/Text/MultiText';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

import Info from '../../Modals/Info';

import Female from '../../Assets/Icons/FemaleSymbol_Color.png';
import LeftArrow from '../../Assets/Icons/LeftArrow.png';
import Male from '../../Assets/Icons/MaleSymbol_Color.png';
import RightArrow from '../../Assets/Icons/RightArrow.png';

import { SetLanguage } from '../../Functions';

const FormatMentionText = ({ ClientName, OrganizationName, OriginalText, Regex, ReturnType = 'Element', SignedBy, SignedDate, TrainerName, UserType, Values }) => { 
    if (!OriginalText) return;
    if (!Values.length) return OriginalText;

    var ReplaceText = {
        'CLIENT_FULLNAME': { Props: { UserType: 'Client' }, Text: ClientName },
        'ORGANIZATION_FULLNAME': { Props: { UserType: 'Organization' }, Text: OrganizationName },
        'SIGNED_BY': { Props: { UserType }, Text: SignedBy },
        'SIGNED_DATE': { Props: { UserType }, Text: SignedDate },
        'TRAINER_FULLNAME': { Props: { UserType: 'Trainer' }, Text: TrainerName }
    };

    if (ReturnType === 'Element') {
        return (
            <>
                {
                    OriginalText.split(Regex).reduce((prev, current, i) => {
                        if (!i) return [current];
    
                        if (Values.includes(current)) {
                            var ReplacingProps = ReplaceText[current].Props;
                            var ReplacingText = ReplaceText[current].Text;
    
                            return prev.concat(<FullNameText key={i + current} {...ReplacingProps}>{ReplacingText}</FullNameText>);
                        }
    
                        return prev.concat(current);
                    }, [])
                }
            </>
        );
    }

    return OriginalText.split(Regex).reduce((prev, current, i) => {
        if (!i) return [current];

        if (Values.includes(current)) return prev.concat(ReplaceText[current].Text);

        return prev.concat(current);
    }, []).join('');
};

class Contract extends React.Component {
    _isMounted = true;

    state = {
        AccuracyStatement: '',
        contractSigned: null,
        ContractAnswers: {},
        IsPreRegistered: null,
        Loading: true,
        RegistrationFields: {
            CheckingUserName: false,
            ConfirmPassword: '',
            DOB: '',
            Email: '',
            FirstName: '',
            Gender: null,
            HoverShowPasswords: false,
            LastCheckedUserName: '',
            LastName: '',
            LanguageId: null,
            Password: '',
            ShowPasswords: false,
            UserName: '',
            UserNameTaken: false
        },
        ScreenNumber: 1,
        ShowCancelContractModal: false,
        TermsConditions: '',
        TotalQuestions: []
    };

    componentDidMount() {
        this._isMounted = true;

        this.onViewContractDetail();
    }
    
    componentWillUnmount() {
        this._isMounted = false;
    }

    onAcceptContract = () => {
        var { ContractId } = this.props.match.params;
        var { AccuracyStatement, ContractDetails: { OrganizationName, Trainer }, TermsConditions } = this.props.ContractDetail;
        var { ContractAnswers: { FirstName, HealthDeclarations: OriginalHealthDeclarations, LastName } } = this.state;

        AccuracyStatement = FormatMentionText({ ClientName: `${FirstName} ${LastName}`, OrganizationName, OriginalText: AccuracyStatement, Regex: new RegExp(/\{(.*?)\}/), ReturnType: 'Text', TrainerName: Trainer, Values: [ 'ORGANIZATION_FULLNAME', 'TRAINER_FULLNAME', 'CLIENT_FULLNAME' ] });
        TermsConditions = FormatMentionText({ ClientName: `${FirstName} ${LastName}`, OrganizationName, OriginalText: TermsConditions, Regex: new RegExp(/\{(.*?)\}/), ReturnType: 'Text', TrainerName: Trainer, Values: [ 'ORGANIZATION_FULLNAME', 'TRAINER_FULLNAME', 'CLIENT_FULLNAME' ] });

        var HealthDeclarations = OriginalHealthDeclarations.map(({ AdditionalInfoResponse, Declaration, Id, Response }) => ({ ContractId, Declaration, Id, Response: Response === 'Yes' ? 1 : 0, AdditionalInfo: AdditionalInfoResponse || '' }));

        const SubscriberId = localStorage.getItem("SubscriberId");
        localStorage.removeItem("SubscriberId");

        if (!this.props.TryingAcceptContract) this.props.AcceptContract({ AccuracyStatement, ContractId, HealthDeclarations, SubscriberId, TermsConditions });
    }

    onAnswerHealthDeclaration = ({ AdditionalInfoTrigger, CurrentHDScreen, Response }) => {
        var UpdatedHealthDeclarations = [ ...this.state.ContractAnswers.HealthDeclarations ];
        UpdatedHealthDeclarations[CurrentHDScreen].Response = Response;

        this.setState({ ContractAnswers: { ...this.state.ContractAnswers, HealthDeclarations: UpdatedHealthDeclarations } }, () => {
            if (AdditionalInfoTrigger && AdditionalInfoTrigger !== Response) this.onChangeScreenNumber(1, 1, 0);
        });
    }

    onBlurUserName = () => {
        var { LastCheckedUserName, UserName } = this.state.RegistrationFields;

        if (LastCheckedUserName !== UserName && !!UserName) {
            this.setState({ RegistrationFields: { ...this.state.RegistrationFields, CheckingUserName: true } }, () => {
                this.props.ValidateUser({ UserName }).then(({ UserNameTaken }) => {
                    this.setState({ RegistrationFields: { ...this.state.RegistrationFields, CheckingUserName: false, LastCheckedUserName: UserName, UserNameTaken: !!UserNameTaken } });
                });
            });
        }
    }

    onChangeInput = ({ event, InputName }) => {
        this.setState({ ContractAnswers: { ...this.state.ContractAnswers, [InputName]: event.target.value } });
    }

    onChangeCity = ({ CityId, CityName }) => {
        if (this.state.ContractAnswers.CityId !== CityId) this.setState({ ContractAnswers: { ...this.state.ContractAnswers, CityId, CityName } });
    }

    onChangeCountry = ({ CountryId, CountryName }) => {
        if (this.state.ContractAnswers.CountryId !== CountryId) this.setState({ ContractAnswers: { ...this.state.ContractAnswers, CityId: null, CityName: '', CountryId, CountryName, StateId: null, StateName: '' } });
    }

    onChangeHealthDeclarationAdditionalInfo = ({ event, CurrentHDScreen }) => {
        var UpdatedHealthDeclarations2 = [ ...this.state.ContractAnswers.HealthDeclarations ];
        UpdatedHealthDeclarations2[CurrentHDScreen].AdditionalInfoResponse = event.target.value;

        this.setState({ ContractAnswers: { ...this.state.ContractAnswers, HealthDeclarations: UpdatedHealthDeclarations2 } });
    }

    onChangeLanguageId = LanguageId => {
        SetLanguage(LanguageId);
        this.setState({ RegistrationFields: { ...this.state.RegistrationFields, LanguageId } });
    }

    onChangePasswordInput = ({ InputType, InputValue }) => {
        this.setState({ RegistrationFields: { ...this.state.RegistrationFields, [InputType]: InputValue } });
    }

    onChangeRegistrationInput = ({ InputName, InputValue }) => {
        var NewRegistrationFields = { ...this.state.RegistrationFields };
        NewRegistrationFields[InputName] = InputValue;

        this.setState({ RegistrationFields: NewRegistrationFields });
    }

    onChangeScreenNumber = (Direction, CanGoNext, LastScreen) => {
        var { AccuracyStatement, ContractDetails: { OrganizationName, Trainer }, IsSubscription, TermsConditions } = this.props.ContractDetail;
        var { ContractAnswers: { FirstName, LastName }, ScreenNumber } = this.state;

        ScreenNumber = Direction === 1 ? ScreenNumber + 1 : ScreenNumber - 1;

        if (Direction === 1 && CanGoNext && LastScreen) {
            if (IsSubscription) this.onSignSubscriptionFlow_AddCard();
            else this.onAcceptContract();
        }
        else if (Direction === 1 && CanGoNext) {
            if (ScreenNumber === 2) {
                AccuracyStatement = FormatMentionText({ ClientName: `${FirstName} ${LastName}`, OrganizationName, OriginalText: AccuracyStatement, Regex: new RegExp(/\{(.*?)\}/), TrainerName: Trainer, Values: [ 'ORGANIZATION_FULLNAME', 'TRAINER_FULLNAME', 'CLIENT_FULLNAME' ] });
                TermsConditions = FormatMentionText({ ClientName: `${FirstName} ${LastName}`, OrganizationName, OriginalText: TermsConditions, Regex: new RegExp(/\{(.*?)\}/), TrainerName: Trainer, Values: [ 'ORGANIZATION_FULLNAME', 'TRAINER_FULLNAME', 'CLIENT_FULLNAME' ] });

                this.setState({ AccuracyStatement, ScreenNumber, TermsConditions }, () => this.onEditUserProfile());
            }
            else this.setState({ ScreenNumber });
        }
        else if (Direction === -1) this.setState({ ScreenNumber });
    }

    onChangeState = ({ StateId, StateName }) => {
        if (this.state.ContractAnswers.StateId !== StateId) this.setState({ ContractAnswers: { ...this.state.ContractAnswers, CityId: null, CityName: '', StateId, StateName } });
    }

    onEditUserProfile = () => {
        var { ContractDetail: { UserId } } = this.props;
        var { FirstName, FullAddress, LastName  } = this.state.ContractAnswers;

        this.props.EditUserProfile({ FirstName, FullAddress, LastName, UserId });
    }

    onFocusCities = () => {
        var { Cities, TryingGetCities } = this.props;
        var { StateId } = this.state.ContractAnswers;

        if (!Cities.hasOwnProperty(`${StateId}`) && !TryingGetCities) this.props.ViewCities({ StateId });
    }

    onFocusCountries = () => {
        var { Countries, TryingGetCountries } = this.props;
        if (!Countries.length && !TryingGetCountries) this.props.ViewCountries();
    }

    onFocusStates = () => {
        var { States, TryingGetStates } = this.props;
        var { CountryId } = this.state.ContractAnswers;

        if (!States.hasOwnProperty(`${CountryId}`) && !TryingGetStates) this.props.ViewStates({ CountryId });
    }

    onRegisterUser = () => {
        var { ContractDetail: { UserDetails: { Email } }, TrainerRequests } = this.props;
        var { DOB, FirstName, Gender, LanguageId, LastName, Password, UserName } = this.state.RegistrationFields;

        this.props.RegisterUser({ DOB, Email, FirstName, Gender, LanguageId, LastName, Password, UserName }).then(() => {
            if (!this.props.TryingRegisterUserError) {
                if (!TrainerRequests.length) this.onViewContractDetail();
                else this.setState({ IsPreRegistered: 0 });
            }
        });
    }

    onRejectContract = () => {
        if (!this.props.TryingRejectContract) {
            var { ContractId } = this.props.match.params;

            this.onToggleShowCancelContractModal(false);
            this.props.RejectContract({ ContractId }).then(() => this.onViewContractDetail());
        }
    }

    onRespondTrainerRequest = ({ RequestType, TrainerId }) => {
        var { UserId } = this.props.ContractDetail;

        RequestType = RequestType.toLowerCase();

        this.props.RespondToTrainerRequest({ RequestType, TrainerId, UserId }).then(() => this.onViewContractDetail());
    }

    onSearchAddress = Search => {
        return this.props.SearchGoogleMapsAutocomplete(Search);
    }

    onSelectLocation = async ({ value }) => {
        geocodeByPlaceId(value.place_id).then(results => {
            console.log('results: ', results);
            var ContractAnswers = { ...this.state.ContractAnswers };

            if (results.length) ContractAnswers.FullAddress = results[0].formatted_address;
            else ContractAnswers.FullAddress = '';

            this.setState({ ContractAnswers });
        })
        .catch(error => console.error(error))
    }

    onSignSubscriptionFlow_AddCard = () => {
        var { ContractId } = this.props.match.params;
        var { ContractDetails: { SubscriptionDetails, TrainerId }, UserId } = this.props.ContractDetail;
        var { ContractAnswers: { HealthDeclarations: OriginalHealthDeclarations } } = this.state;

        var { SubAmount } = SubscriptionDetails;
        var TimeZoneOffset = new Date().toString().match(/([-+][0-9]+)\s/)[1];
        TimeZoneOffset = TimeZoneOffset.slice(0, 3) + ":" + TimeZoneOffset.slice(3);

        var CancelUrl = `${ReturnUrl_SubscriptionSigning}Cancel/${ContractId}`;
        var ActualReturnUrl = `${ReturnUrl_SubscriptionSigning}AddSubscriber/${ContractId}`;

        var HealthDeclarations = OriginalHealthDeclarations.map(({ AdditionalInfoResponse, Declaration, Id, Response }) => ({ ContractId, Declaration, Id, Response, AdditionalInfo: AdditionalInfoResponse || '' }));

        if (!this.props.TryingInitiateInvoicePayment) {
            this.props.InitiateSubscriptionAcceptancePayment({ CancelUrl, ContractId, HealthDeclarations, ReturnUrl: ActualReturnUrl, SubAmount, TimeZoneOffset, TrainerId, UserId }).then(({ HostedPaymentPageUrl, SubscriberId }) => {
                localStorage.setItem("SubscriberId", SubscriberId);
                window.location.href = HostedPaymentPageUrl;
            });
        }
    }

    onToggleHoverShowPasswords = () => {
        this.setState({ RegistrationFields: { ...this.state.RegistrationFields, HoverShowPasswords: !this.state.RegistrationFields.HoverShowPasswords } });
    }

    onToggleShowCancelContractModal = ShowCancelContractModal => {
        this.setState({ ShowCancelContractModal });
    }

    onToggleShowPasswords = () => {
        this.setState({ RegistrationFields: { ...this.state.RegistrationFields, ShowPasswords: !this.state.RegistrationFields.ShowPasswords} });
    }

    onViewContractDetail = () => {
        var { ContractId } = this.props.match.params;

        this.props.ViewContractDetail({ ContractId }).then(() => {
            var { AccuracyStatement, ContractDetails: { OrganizationName, Trainer }, HealthDeclarations, IsSubscription, NeedsFullName, TermsConditions, TrainerOrganizations, UserDetails, UserSigned } = this.props.ContractDetail;

            var { AddressLine1, AddressLine2, CityName, CountryName, StateName, Zip, Email, FirstName, FullAddress, LanguageId, LastName } = UserDetails;
            UserDetails.FullAddress = FullAddress ? FullAddress : `${AddressLine1} ${AddressLine2}, ${CityName}, ${StateName} ${Zip}, ${CountryName}`;

            if (!!+LanguageId) SetLanguage(+LanguageId);

            var TotalQuestionsCount = 0;
            var TotalQuestions = [];

            if (!NeedsFullName && !!+LanguageId) {
                AccuracyStatement = FormatMentionText({ ClientName: `${FirstName} ${LastName}`, OrganizationName, OriginalText: AccuracyStatement, Regex: new RegExp(/\{(.*?)\}/), TrainerName: Trainer, Values: [ 'ORGANIZATION_FULLNAME', 'TRAINER_FULLNAME', 'CLIENT_FULLNAME' ] });
                TermsConditions = FormatMentionText({ ClientName: `${FirstName} ${LastName}`, OrganizationName, OriginalText: TermsConditions, Regex: new RegExp(/\{(.*?)\}/), TrainerName: Trainer, Values: [ 'ORGANIZATION_FULLNAME', 'TRAINER_FULLNAME', 'CLIENT_FULLNAME' ] });
            }

            const QueryValues1 = queryString.parse(this.props.location.search);
            var contractSigned = QueryValues1.contractSigned;

            if (contractSigned) UserSigned = contractSigned;

            if (!UserSigned) {
                // User Details Screen, Contract Products Screen, Payment Terms Screen, Summary, Accuracy, Terms Conditions, Final Agreement
                TotalQuestionsCount += 7;

                if (!!TrainerOrganizations.length) TotalQuestionsCount++;

                // Health Declarations
                TotalQuestionsCount += HealthDeclarations.length;

                if (!HealthDeclarations.length) {
                    TotalQuestionsCount -= 1;
                }

                for (var i = 0; i < TotalQuestionsCount; i++) {
                    TotalQuestions.push(i + 1);
                }
            }

            // FirstName = FirstName || '';
            // LastName = LastName || '';
            var RegistrationFields = { ...this.state.RegistrationFields, Email, FirstName, LastName };

            this.setState({ AccuracyStatement, contractSigned, ContractAnswers: { ...UserDetails, HealthDeclarations }, IsPreRegistered: this.props.IsPreRegistered, Loading: false, RegistrationFields, TermsConditions, TermsConditions_Original: TermsConditions, TotalQuestions }, () => {
                if (!IsSubscription && !UserSigned) {
                    const QueryValues = queryString.parse(this.props.location.search);
    
                    if (Object.entries(QueryValues).length > 0 && QueryValues.paymentid) this.onAcceptContract();
                }
            });
        });
    }

    renderAccuracyScreen = AccuracyStatement => {
        if (!AccuracyStatement) AccuracyStatement = this.state.AccuracyStatement;

        return <TermsConditionsContainer>{AccuracyStatement}</TermsConditionsContainer>;
    }

    renderAddress = () => {
        var { t } = this.props;
        var { FullAddress } = this.state.ContractAnswers;

        return (
            <NameContainer className="NameContainer">
                <PageText FontFamily="semibold" FontSize="medium-3" JustifyContent="flex-start" NoMargin Text={t('address')} TextAlign="left" />

                <Spacer Size="small" />

                <GooglePlacesAutocomplete
                    autocompletionRequest={{
                        types: [ 'address' ]
                    }}
                    selectProps={{ value: FullAddress, onChange: this.onSelectLocation }}
                />

                <Spacer Size="small" />

                {FullAddress && <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={FullAddress} TextAlign="left" />}
            </NameContainer>
        );
    }

    renderContractPartiesText = () => {
        var { t } = this.props;
        var { ContractDetails: { OrganizationName, Trainer } } = this.props.ContractDetail;
        var { ContractAnswers: { FirstName, LastName } } = this.state;

        var ContractPartiesText = FormatMentionText({ ClientName: `${FirstName} ${LastName}`, OrganizationName, OriginalText: t('contract_parties_in_deal'), Regex: new RegExp(/\{(.*?)\}/), TrainerName: Trainer, Values: [ 'ORGANIZATION_FULLNAME', 'TRAINER_FULLNAME', 'CLIENT_FULLNAME' ] });

        return <ContractParties>{ContractPartiesText}</ContractParties>;
    }

    renderContractPreview = () => {
        var { t } = this.props;
        var { CancelledDate, ContractDetails: { Trainer }, FirstName, IsCancelled, IsRejected, LastName, RejectedDate, TrainerOrganizations, TrainerSigned, UserSigned } = this.props.ContractDetail;

        var UserActionDate = `${!!+IsCancelled ? CancelledDate : !!+IsRejected ? RejectedDate : UserSigned}Z`;


        var TrainerSignedText = FormatMentionText({ OriginalText: t('contract_signed_by_datetime'), Regex: new RegExp(/\{(.*?)\}/), SignedBy: Trainer, SignedDate: moment(`${TrainerSigned}Z`).format('[TrackingHistory]'), UserType: 'Trainer', Values: [ 'SIGNED_BY', 'SIGNED_DATE' ] });
        var UserSignedText = FormatMentionText({ OriginalText: t('contract_signed_by_datetime'), Regex: new RegExp(/\{(.*?)\}/), SignedBy: `${FirstName} ${LastName}`, SignedDate: moment(`${UserSigned}Z`).format('[TrackingHistory]'), UserType: 'Client', Values: [ 'SIGNED_BY', 'SIGNED_DATE' ] });

        return (
            <>
                {
                    !UserSigned &&
                    <>
                        <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text={t('_contract_processing')} TextAlign="center" />

                        <Spacer Size="small" />

                        <StyledHR />

                        <Spacer Size="small" />
                    </>
                }

                {
                    (!!+IsCancelled || !!+IsRejected) &&
                    <>
                        <PageText
                            FontColor="red-bittersweet"
                            FontFamily="semibold"
                            FontSize="medium-2"
                            JustifyContent="center"
                            NoMargin
                            Text={!!+IsCancelled ? t('contract_cancelled_by_datetime', { SIGNED_BY: `${FirstName} ${LastName}`, SIGNED_DATE: moment(UserActionDate).format('[TrackingHistory]') }) : t('contract_rejected_by_datetime', { SIGNED_BY: `${FirstName} ${LastName}`, SIGNED_DATE: moment(UserActionDate).format('[TrackingHistory]') })}
                            TextAlign="center"
                        />

                        <Spacer Size="medium" />
                    </>
                }

                {this.renderContractPartiesText()}

                <Spacer Size="extra-small" />

                <MultiText
                    Texts={[
                        { FontFamily: 'semibold', FontSize: 'medium-1', Text: `${t('Date_str')}:` },
                        { FontFamily: 'medium', FontSize: 'medium-1', Text: moment().format('[WeeklyViewHeader]') }
                    ]}
                />

                <Spacer Size="medium" />

                <StyledHR />

                <Spacer Size="medium" />

                {this.renderProductsPreview()}

                <Spacer Size="medium" />

                <StyledHR />

                <Spacer Size="medium" />

                {this.renderInvoicesPreview()}

                <Spacer Size="medium" />

                <StyledHR />

                    <Spacer Size="medium" />

                {
                    !!TrainerOrganizations.length &&
                    <>
                        {this.renderTrainerOrganizations()}

                        <Spacer Size="medium" />

                        <StyledHR />

                        <Spacer Size="medium" />
                    </>
                }

                {this.renderLegal()}

                <Spacer Size="medium" />

                <StyledHR />

                <Spacer Size="medium" />

                {
                    !!UserSigned &&
                    <>
                        <ContractParties>{TrainerSignedText}</ContractParties>
                        {
                            !!UserSigned &&
                            <>
                                <Spacer Size="extra-small" />
                                
                                <ContractParties>{UserSignedText}</ContractParties>
                            </>
                        }
                    </>
                }

                
            </>
        );
    }

    renderHealthDeclaration = () => {
        var { t } = this.props;
        var { TrainerOrganizations } = this.props.ContractDetail;
        var { ContractAnswers: { HealthDeclarations }, ScreenNumber } = this.state;

        var CurrentHDScreen = !!TrainerOrganizations.length ? ScreenNumber - 5 : ScreenNumber - 4;

        var { AdditionalInfoRequired, AdditionalInfoResponse, AdditionalInfoTrigger, Response } = HealthDeclarations[CurrentHDScreen];

        var Buttons = [];
        var Texts = [];
        if (AdditionalInfoTrigger === Response) {
            Buttons.push({ FontFamily: 'semibold', OnClick: () => this.onAnswerHealthDeclaration({ AdditionalInfoTrigger, CurrentHDScreen, Response: 'Yes' }), Selected: Response === 'Yes', Title: t('yes_with_first_char_uppercase') });
            Buttons.push({ FontFamily: 'semibold', OnClick: () => this.onAnswerHealthDeclaration({ AdditionalInfoTrigger, CurrentHDScreen, Response: 'No' }), Selected: Response === 'No', Title: t('no_with_first_char_uppercase') });

            Texts = [
                { FontFamily: 'medium', FontSize: 'medium-2', Text: t('you_answered') },
                { FontFamily: 'semibold', FontSize: 'medium-2', Text: `${Response}.` },
                { FontFamily: 'medium', FontSize: 'medium-2', Text: t('please_specify') }
            ]
            if (!+AdditionalInfoRequired) Texts.push({ FontFamily: 'medium', FontSize: 'medium-2', Text: `(${t('optional').toLowerCase()})` });
        }

        return (
            <HealthDeclarationAnswerContainer className="HealthDeclarationAnswerContainer">
                <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="center" NoMargin Text={HealthDeclarations[CurrentHDScreen].Declaration} TextAlign="center" />

                {
                    AdditionalInfoTrigger === Response &&
                    <>
                        <Spacer Size="medium" />

                        <HealthDeclarationAnswerContainerAdditionalInfo className="HealthDeclarationAnswerContainerAdditionalInfo">
                            <ButtonGroup Buttons={Buttons} ButtonWidth="100px" />

                            <Spacer Size="small" />

                            <MultiText Texts={Texts} />

                            <Spacer Size="medium" />

                            <StyledAdditionalInfoTextArea
                                ControlledComponent
                                OnChange={event => this.onChangeHealthDeclarationAdditionalInfo({ event, CurrentHDScreen })}
                                MaxRows={10}
                                NoMargin
                                NumRows={10}
                                Placeholder={t('write_note_here')}
                                Value={AdditionalInfoResponse || ''}
                            />
                        </HealthDeclarationAnswerContainerAdditionalInfo>
                    </>
                }
            </HealthDeclarationAnswerContainer>
        );
    }

    renderHealthDeclarations = HealthDeclarations => {
        var { t } = this.props;

        return (
            <HealthDeclarationsContainer className="HealthDeclarationsContainer">
                <HealthDeclarationContainer className="HealthDeclarationContainer">
                    <HealthDeclarationTextContainer className="HealthDeclarationTextContainer" />
                    <HealthDeclarationOptionsContainer className="HealthDeclarationOptionsContainer">
                        <PageText FontFamily="bold" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('yes_with_first_char_uppercase')} TextAlign="center" Uppercase />
                        <PageText FontFamily="bold" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('no_with_first_char_uppercase')} TextAlign="center" Uppercase />
                    </HealthDeclarationOptionsContainer>
                </HealthDeclarationContainer>
                {
                    HealthDeclarations.length > 0 && HealthDeclarations.map(({ AdditionalInfo, AdditionalInfoResponse, Declaration, Response }, DeclarationIndex) => {
                        return (
                            <HealthDeclarationContainer className="HealthDeclarationContainer" key={DeclarationIndex}>
                                <HealthDeclarationTextContainer className="HealthDeclarationTextContainer">
                                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={`${DeclarationIndex + 1}. ${Declaration}`} TextAlign="left" />

                                    {
                                        (AdditionalInfo || AdditionalInfoResponse) &&
                                        <>
                                            <Spacer Size="extra-extra-small" />

                                            <PageText FontFamily="semibold-italic" FontSize="small" JustifyContent="flex-start" NoMargin Text={AdditionalInfo || AdditionalInfoResponse} TextAlign="left" />
                                        </>
                                    }
                                </HealthDeclarationTextContainer>
                                <HealthDeclarationOptionsContainer className="HealthDeclarationOptionsContainer">
                                    <FakeCheckmarkBox className="FakeCheckmarkBox" Selected={Response && Response.toLowerCase() === 'yes'} />
                                    <FakeCheckmarkBox className="FakeCheckmarkBox" Selected={Response && Response.toLowerCase() === 'no'} />
                                </HealthDeclarationOptionsContainer>
                            </HealthDeclarationContainer>
                        );
                    })
                }
            </HealthDeclarationsContainer>
        );
    }

    renderInvoicesPreview = () => {
        var { t } = this.props;
        var { ContractDetail: { ContractDetails, IsSubscription } } = this.props;

        if (IsSubscription) {
            var { SubAmountText, BindingPeriod } = ContractDetails.SubscriptionDetails;

            return (
                <>
                    <PageText FontFamily="semibold" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text={t('payment_terms')} TextAlign="left" />

                    <Spacer Size="small" />

                    <MultiText
                        Texts={[
                            { FontFamily: 'medium', FontSize: 'medium-1', Text: t('_payment_subscription1') },
                            { FontFamily: 'semibold', FontSize: 'medium-1', Text: SubAmountText },
                            { FontFamily: 'medium', FontSize: 'medium-1', Text: t('_payment_subscription2') },
                            { FontFamily: 'medium', FontSize: 'medium-1', Text: t('_payment_subscription3') },
                        ]}
                    />

                    <Spacer Size="small" />

                    <PageText FontFamily="semibold" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text={t('_binding_period')} TextAlign="left" />

                    <Spacer Size="small" />

                    <MultiText
                        Texts={[
                            { FontFamily: 'medium', FontSize: 'medium-1', Text: t('_binding_period') },
                            { FontFamily: 'semibold', FontSize: 'medium-1', Text: `${BindingPeriod} ${t('_months').toLowerCase()}` }
                        ]}
                    />

                    <Spacer Size="small" />

                    <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('_binding_period_subscription1')} TextAlign="left" />
                </>
            );
        }

        var { NumPayments, PaymentsDue } = ContractDetails.PaymentSchedule;

        return (
            <>
                <PageText FontFamily="semibold" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text={t('payment_terms')} TextAlign="left" />

                <Spacer Size="small" />

                <MultiText
                    Texts={[
                        { FontFamily: 'medium', FontSize: 'medium-1', Text: t('payments_split_into') },
                        { FontFamily: 'semibold', FontSize: 'medium-1', Text: `${NumPayments} ${t(`${+NumPayments > 1 ? 'payment' : 'payments'}`, { count: +NumPayments }).toLowerCase()}` }
                    ]}
                />

                <Spacer Size="small" />

                <InvoicePreviewContainer className="InvoicePreviewContainer">
                    {
                        PaymentsDue.map(({ AmountText, PaymentDate, PaymentMadeDate }, InvoiceIndex) => {
                            var DaysLate = PaymentMadeDate ? moment(PaymentDate).diff(moment(PaymentMadeDate), 'days') : moment(PaymentDate).diff(moment().utc().format('YYYY-MM-DD'), 'days');
                            var IsLate = DaysLate >= 0 ? 0 : 1;

                            return (
                                <InvoicePreviewSubContainer className="InvoicePreviewSubContainer" key={InvoiceIndex}>
                                    <StyledPaymentNumberPageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin NoWrap Text={`${t('contracts_invoice_part_number', { PartNumber: InvoiceIndex + 1 })}:`} TextAlign="left" />

                                    <InvoicePreviewAmountContainer className="InvoicePreviewAmountContainer">
                                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={`${t('Price')}:`} TextAlign="left" WhiteSpace="nowrap" />
                                        <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={AmountText} TextAlign="left" WhiteSpace="nowrap" />
                                    </InvoicePreviewAmountContainer>
                                    <InvoicePreviewDateContainer>
                                        <PageText FontColor={IsLate && !PaymentMadeDate ? 'red-bittersweet' : 'black'} FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={`${t('contracts_invoice_deadline_date')}:`} TextAlign="left" WhiteSpace="nowrap" />
                                        <PageText FontColor={IsLate && !PaymentMadeDate ? 'red-bittersweet' : 'black'} FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={moment(PaymentDate).format('[NumbersDate]')} TextAlign="left" WhiteSpace="nowrap" />
                                    </InvoicePreviewDateContainer>
                                    <PaidStatusContainer className="PaidStatusContainer">
                                        {this.renderPaidStatus({ DaysLate, IsLate, PaymentMadeDate })}
                                    </PaidStatusContainer>
                                </InvoicePreviewSubContainer>
                            );
                        })
                    }
                </InvoicePreviewContainer>

                <Spacer Size="small" />

                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('payment_made_through_abel')} TextAlign="left" />
            </>
        );
    }

    renderLastChanceScreen = () => {
        var { t } = this.props;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="center" NoMargin Text={t('last_step')} TextAlign="center" />

                <Spacer Size="medium" />

                <PageText FontFamily="semibold" FontSize="medium-2" JustifyContent="center" NoMargin Text={t('do_you_want_to_sign_the_contract')} TextAlign="center" />
            </>
        );
    }

    renderLegal = () => {
        var { t } = this.props;
        var { HealthDeclarations } = this.props.ContractDetail;
        var { AccuracyStatement, TermsConditions } = this.state;

        return (
            <>
                {
                    HealthDeclarations.length > 0 &&
                    <>
                        <PageText FontFamily="semibold" FontSize="medium-2" JustifyContent="center" NoMargin Text={t('health_declarations')} TextAlign="center" />

                        <Spacer Size="small" />

                        {this.renderHealthDeclarations(HealthDeclarations)}

                        <Spacer Size="small" />
                    </>
                }

                {this.renderAccuracyScreen(AccuracyStatement)}

                <Spacer Size="medium" />

                <PageText FontFamily="semibold" FontSize="medium-2" JustifyContent="center" NoMargin Text={t('other_general_terms')} TextAlign="center" />

                <Spacer Size="small" />

                {this.renderTermsConditionsScreen(TermsConditions)}
            </>
        );
    }

    renderMultiButton = () => {
        var { t } = this.props;
        var { TrainerOrganizations } = this.props.ContractDetail;
        var { ContractAnswers: { FirstName, FullAddress, HealthDeclarations, LastName }, ScreenNumber, TotalQuestions } = this.state;

        var CanGoNext = 1;
        var LastScreen = ScreenNumber === TotalQuestions.length;

        if (ScreenNumber === 1 && (!FirstName || !LastName || !FullAddress)) CanGoNext = 0;

        var Buttons = [];
        
        // Personal Details Screen, So Have Next Button
            if (ScreenNumber === 1) Buttons.push({ BackgroundColor: 'abel-blue', BackgroundColorHover: 'abel-blue-hover', Border: 'none', Color: 'white', ColorHover: 'white', Disabled: !CanGoNext, FontFamily: 'semibold', OnClick: () => this.onChangeScreenNumber(1, CanGoNext, LastScreen), Title: t('next_with_first_char_uppercase') });
        // Health Declaration Question, So Make Bottom Buttons Be (Yes && No) || Next
            else if (
                (!!TrainerOrganizations.length && ScreenNumber >= 5 && ScreenNumber <= (HealthDeclarations.length + 4)) ||
                (!TrainerOrganizations.length && ScreenNumber >= 4 && ScreenNumber <= (HealthDeclarations.length + 3))
            ) {
                var CurrentHDScreen = !!TrainerOrganizations.length ? ScreenNumber - 5 : ScreenNumber - 4;

                var { AdditionalInfoRequired, AdditionalInfoResponse, AdditionalInfoTrigger, Response } = HealthDeclarations[CurrentHDScreen];

                if (AdditionalInfoTrigger !== Response) {
                    Buttons.push({ BackgroundColor: 'blue-astronaut', BackgroundColorHover: 'blue-astronaut-hover', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', OnClick: () => this.onAnswerHealthDeclaration({ AdditionalInfoTrigger, CurrentHDScreen, Response: 'Yes' }), Title: t('yes_with_first_char_uppercase') });
                    Buttons.push({ BackgroundColor: 'abel-blue', BackgroundColorHover: 'abel-blue-hover', Border: 'none', Color: 'white', ColorHover: 'white', Disabled: !CanGoNext, FontFamily: 'semibold', OnClick: () => this.onAnswerHealthDeclaration({ AdditionalInfoTrigger, CurrentHDScreen, Response: 'No' }), Title: t('no_with_first_char_uppercase') });
                } else {
                    if (!!+AdditionalInfoRequired && AdditionalInfoTrigger === Response && !AdditionalInfoResponse) CanGoNext = 0;

                    Buttons.push({ BackgroundColor: 'abel-blue', BackgroundColorHover: 'abel-blue-hover', Border: 'none', Color: 'white', ColorHover: 'white', Disabled: !CanGoNext, FontFamily: 'semibold', OnClick: () => this.onChangeScreenNumber(1, CanGoNext, LastScreen), Title: t('next_with_first_char_uppercase') });
                }
            }
        // Non-Health Declaration Question, So Make Bottom Buttons Be Accept / Reject
            else {
                Buttons.push({ BackgroundColor: 'red-bittersweet', BackgroundColorHover: 'red-bittersweet-hover', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', OnClick: () => this.onToggleShowCancelContractModal(true), Title: t('do_not_agree_cancel') });
                Buttons.push({ BackgroundColor: 'abel-blue', BackgroundColorHover: 'abel-blue-hover', Border: 'none', Color: 'white', ColorHover: 'white', Disabled: !CanGoNext, FontFamily: 'semibold', OnClick: () => this.onChangeScreenNumber(1, CanGoNext, LastScreen), Title: t('agree') });
            }
        
        return <BackNextButtons Buttons={Buttons} />;
    }

    renderName = () => {
        var { t } = this.props;
        var { FirstName, LastName } = this.state.ContractAnswers;

        return (
            <NameContainer className="NameContainer">
                <PageText FontFamily="semibold" FontSize="medium-3" JustifyContent="flex-start" NoMargin Text={t('provide_full_name')} TextAlign="left" />

                <Spacer Size="small" />

                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('first_name')} TextAlign="left" />

                <Spacer Size="extra-extra-small" />

                <StyledInput
                    FontFamily="medium"
                    FontSize="medium-2"
                    NoLabel
                    NoMargin
                    OnChange={event => this.onChangeInput({ event, InputName: 'FirstName' })}
                    Placeholder={t('first_name')}
                    Size="auto"
                    Type="text"
                    Value={FirstName}
                />

                <Spacer Size="small" />

                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('last_name')} TextAlign="left" />

                <Spacer Size="extra-extra-small" />

                <StyledInput
                    FontFamily="medium"
                    FontSize="medium-2"
                    NoLabel
                    NoMargin
                    OnChange={event => this.onChangeInput({ event, InputName: 'LastName' })}
                    Placeholder={t('last_name')}
                    Size="auto"
                    Type="text"
                    Value={LastName}
                />
            </NameContainer>
        );
    }

    renderPaidStatus = ({ DaysLate, IsLate, PaymentMadeDate }) => {
        var { t } = this.props;
        var PaymentStatus = PaymentMadeDate ? 'Paid' : 'Pending';

        // Paid
            if (PaymentStatus === 'Paid') {
                return (
                    <>
                        <ProductsListBlockRowContainer className="ProductsListBlockRowContainer">
                            <PageText FontColor="green-success" FontFamily="bold" FontSize="small" JustifyContent="flex-start" NoMargin NoWrap Text={`${t('contracts_invoice_paid')}:`} TextAlign="left" Uppercase />
                            <DetailText FontColor="green-success">{moment(PaymentMadeDate).format('[NumbersDate]')}</DetailText>
                        </ProductsListBlockRowContainer>
                        <OnTimeContainer className="OnTimeContainer">
                            {
                                IsLate ?
                                <PageText FontColor="orange-selectiveyellow" FontFamily="medium-italic" FontSize="small" JustifyContent="flex-start" NoMargin NoWrap Text={t('contracts_invoice_days_late', { DaysLate: Math.abs(DaysLate) })} TextAlign="left" />
                            :
                                <PageText FontColor="green-success" FontFamily="medium-italic" FontSize="small" JustifyContent="flex-start" NoMargin NoWrap Text={t('contracts_invoice_on_time')} TextAlign="left" />
                            }
                        </OnTimeContainer>
                    </>
                );
            }
        // Pending
            else if (PaymentStatus === 'Pending') {
                if (IsLate) {
                    return (
                        <>
                            <ProductsListBlockRowContainer className="ProductsListBlockRowContainer">
                                <PageText FontColor="red-bittersweet" FontFamily="bold" FontSize="small" JustifyContent="flex-start" NoMargin NoWrap Text={t('contracts_invoice_not_paid')} TextAlign="left" Uppercase />
                            </ProductsListBlockRowContainer>
                            <OnTimeContainer className="OnTimeContainer">
                                <PageText FontColor="red-bittersweet" FontFamily="medium-italic" FontSize="small" JustifyContent="flex-start" NoMargin NoWrap Text={t('contracts_invoice_days_late', { DaysLate: Math.abs(DaysLate) })} TextAlign="left" />
                            </OnTimeContainer>
                        </>
                    );
                }
                
                return (
                    <ProductsListBlockRowContainer className="ProductsListBlockRowContainer">
                        <PageText FontFamily="bold" FontSize="small" JustifyContent="flex-start" NoMargin NoWrap Text={`${DaysLate} ${t('days').toLowerCase()}`} TextAlign="left" />
                        <OnTimeContainer className="OnTimeContainer">
                            <PageText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin NoWrap Text={t('contracts_invoice_until_deadline')} TextAlign="left" />
                        </OnTimeContainer>
                    </ProductsListBlockRowContainer>
                );
            }
    }

    renderPersonalDetailsScreen = () => {
        var { t } = this.props;

        return (
            <PersonalDetailsContainer className="PersonalDetailsContainer">
                <PageText FontFamily="semibold" FontSize="medium-3" JustifyContent="center" NoMargin Text={t('your_information')} TextAlign="center" />

                <Spacer Size="extra-small" />

                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('your_information_info')} TextAlign="center" />

                <Spacer Size="medium" />

                {this.renderName()}

                <Spacer Size="large" />

                {this.renderAddress()}
            </PersonalDetailsContainer>
        );
    }

    renderProductsPreview = () => {
        var { t } = this.props;
        var { Device, ContractDetail: { ContractDetails, IsSubscription } } = this.props;

        var { Products, TotalPriceText } = ContractDetails;

        return (
            <>
                {
                    Device === 'laptop' ?
                    <ProductsListTableContainer className="ProductsListTableContainer">
                        <ProductsListTableRowContainer className="ProductsListTableRowContainer" IsSubscription={IsSubscription}>
                            <PageText FontFamily="bold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('product')} TextAlign="left" />
                            {!IsSubscription && <PageText FontFamily="bold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('Quantity')} TextAlign="left" />}
                            <PageText FontFamily="bold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('Price')} TextAlign="left" />
                            <PageText FontFamily="bold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('Discount')} TextAlign="left" />
                        </ProductsListTableRowContainer>
                        {
                            Products.map(({ DiscountText, ProductName, Quantity, TotalPriceText }, ProductIndex) => {
                                return (
                                    <ProductsListTableRowContainer key={ProductIndex} className="ProductsListTableRowContainer" IsSubscription={IsSubscription}>
                                        <DetailText>{ProductName}</DetailText>
                                        {!IsSubscription && <DetailText>{`${Quantity}`}</DetailText>}
                                        <DetailText>{TotalPriceText}</DetailText>
                                        <DetailText>{DiscountText}</DetailText>
                                    </ProductsListTableRowContainer>
                                );
                            })
                        }
                    </ProductsListTableContainer>
                :
                    <ProductsListBlocksContainer className="ProductsListBlocksContainer">
                        {
                            Products.map(({ DiscountText, ProductName, Quantity, TotalPriceText }, ProductIndex) => {
                                return (
                                    <ProductsListBlockContainer key={ProductIndex}>
                                        <ProductsListBlockRowContainer>
                                            <PageText FontFamily="bold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={`${t('product')}:`} TextAlign="left" />
                                            <DetailText>{ProductName}</DetailText>
                                        </ProductsListBlockRowContainer>
                                        {
                                            !IsSubscription &&
                                            <ProductsListBlockRowContainer>
                                                <PageText FontFamily="bold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={`${t('Quantity')}:`} TextAlign="left" />
                                                <DetailText>{`${Quantity}`}</DetailText>
                                            </ProductsListBlockRowContainer>
                                        }
                                        <ProductsListBlockRowContainer>
                                            <PageText FontFamily="bold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={`${t('Price')}:`} TextAlign="left" />
                                            <DetailText>{TotalPriceText}</DetailText>
                                        </ProductsListBlockRowContainer>
                                        <ProductsListBlockRowContainer>
                                            <PageText FontFamily="bold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={`${t('Discount')}:`} TextAlign="left" />
                                            <DetailText>{DiscountText}</DetailText>
                                        </ProductsListBlockRowContainer>
                                    </ProductsListBlockContainer>
                                );
                            })
                        }
                    </ProductsListBlocksContainer>
                }

                {
                    !IsSubscription &&
                    <>
                        <Spacer Size="extra-small" />
        
                        <ProductsListBlockRowContainer>
                            <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={`${t('total_price')}: `} TextAlign="left" />
                            <DetailText FontFamily="bold" Underline>{TotalPriceText}</DetailText>
                        </ProductsListBlockRowContainer>
                    </>
                }

                <Spacer Size="medium" />

                <ProductDescriptionsContainer>
                    {
                        Products.map(({ ProductDescription, ProductName }, ProductIndex) => {
                            return (
                                <ProductDescriptionContainer key={ProductIndex}>
                                    <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={`${t('product_description')} ${ProductName}:`} TextAlign="left" />
                                    
                                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={ProductDescription} TextAlign="left" />
                                </ProductDescriptionContainer>
                            );
                        })
                    }
                </ProductDescriptionsContainer>
            </>
        );
    }

    renderQuestionCount = () => {
        var { Device } = this.props;
        var { TrainerOrganizations } = this.props.ContractDetail;
        var { ContractAnswers: { FirstName, FullAddress, HealthDeclarations, LastName }, ScreenNumber, TotalQuestions } = this.state;

        var CanGoNext = 1;
        var LastScreen = ScreenNumber === TotalQuestions.length;

        if (ScreenNumber === 1 && (!FirstName || !LastName || !FullAddress)) CanGoNext = 0;

        if (
            (!!TrainerOrganizations.length && ScreenNumber >= 5 && ScreenNumber <= (HealthDeclarations.length + 4)) ||
            (!TrainerOrganizations.length && ScreenNumber >= 4 && ScreenNumber <= (HealthDeclarations.length + 3))
        ) {
            var CurrentHDScreen = !!TrainerOrganizations.length ? ScreenNumber - 5 : ScreenNumber - 4;

            var { AdditionalInfoRequired, AdditionalInfoResponse, AdditionalInfoTrigger, Response } = HealthDeclarations[CurrentHDScreen];

            if (!Response || (!!+AdditionalInfoRequired && AdditionalInfoTrigger === Response && !AdditionalInfoResponse)) CanGoNext = 0;
        }

        return (
            <QuestionCountContainer className="QuestionCountContainer">
                {ScreenNumber !== 1 ? <StyledArrowIcon ImageSrc={LeftArrow} SelfLoading OnClick={() => this.onChangeScreenNumber(-1)} /> : <div></div>}
                
                {
                    Device === 'laptop' ?
                    <QuestionDotsContainer className="QuestionDotsContainer">
                        {TotalQuestions.map(QuestionNumber => <QuestionCount className="QuestionCount" key={QuestionNumber} Selected={ScreenNumber === QuestionNumber} />)}
                    </QuestionDotsContainer>
                :
                    <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="center" NoMargin Text={`${ScreenNumber} / ${TotalQuestions.length}`} TextAlign="center" />
                }

                {(!LastScreen && CanGoNext) ? <StyledArrowIcon ImageSrc={RightArrow} SelfLoading OnClick={() => this.onChangeScreenNumber(1, CanGoNext, LastScreen)} /> : <div></div>}
            </QuestionCountContainer>
        );
    }

    renderQuestionScreen = () => {
        var { t } = this.props;
        var { HealthDeclarations, TrainerOrganizations } = this.props.ContractDetail;
        var { ScreenNumber } = this.state;

        var TOAddExtra = 0;
        if (!!TrainerOrganizations.length) TOAddExtra = 1;
        if (!HealthDeclarations.length) TOAddExtra -= 1;

        if (ScreenNumber === 1) return this.renderPersonalDetailsScreen();
        if (ScreenNumber === 2) {
            return (
                <>
                    {this.renderContractPartiesText()}

                    <Spacer Size="extra-small" />

                    <MultiText
                        Texts={[
                            { FontFamily: 'semibold', FontSize: 'medium-1', Text: `${t('Date_str')}:` },
                            { FontFamily: 'medium', FontSize: 'medium-1', Text: moment().format('[WeeklyViewHeader]') }
                        ]}
                    />

                    <Spacer Size="medium" />

                    {this.renderProductsPreview()}
                </>
            )
        }
        if (ScreenNumber === 3) return this.renderInvoicesPreview();
        if (ScreenNumber === 4 && !!TrainerOrganizations.length) return this.renderTrainerOrganizations();
        if (ScreenNumber >= (4 + TOAddExtra) && ScreenNumber <= (HealthDeclarations.length + (3 + TOAddExtra))) return this.renderHealthDeclaration();
        if (ScreenNumber === (HealthDeclarations.length + (4 + TOAddExtra))) return this.renderSummaryScreen();
        if (ScreenNumber === (HealthDeclarations.length + (5 + TOAddExtra))) return this.renderAccuracyScreen();
        if (ScreenNumber === (HealthDeclarations.length + (6 + TOAddExtra))) {
            return (
                <>
                    <PageText FontFamily="semibold" FontSize="medium-2" JustifyContent="center" NoMargin Text={t('other_general_terms')} TextAlign="center" />

                    <Spacer Size="small" />

                    {this.renderTermsConditionsScreen()}
                </>
            );
        }
        if (ScreenNumber === (HealthDeclarations.length + (7 + TOAddExtra))) return this.renderLastChanceScreen();
    }

    renderRegistration = () => {
        var { t } = this.props;
        var { Device } = this.props;
        var { CheckingUserName, ConfirmPassword, DOB, FirstName, Gender, LastName, LanguageId, Password, ShowPasswords, UserName, UserNameTaken } = this.state.RegistrationFields;

        var Buttons = [];
        Buttons.push({ BackgroundColor: 'white', BackgroundColorHover: 'white-concrete', Border: Gender === 'Female' ? '3px solid var(--blue-astronaut)' : null, Color: 'black', ColorHover: 'black', FontFamily: Gender === 'Female' ? 'semibold' : 'medium', Icon: Female, IconPosition: 'left', OnClick: () => this.onChangeRegistrationInput({ InputName: 'Gender', InputValue: 'Female' }), Title: t('female_with_first_char_uppercase') });
        Buttons.push({ BackgroundColor: 'white', BackgroundColorHover: 'white-concrete', Border: Gender === 'Male' ? '3px solid var(--blue-astronaut)' : null, Color: 'black', ColorHover: 'black', FontFamily: Gender === 'Male' ? 'semibold' : 'medium', Icon: Male, IconPosition: 'left', OnClick: () => this.onChangeRegistrationInput({ InputName: 'Gender', InputValue: 'Male' }), Title: t('male_with_first_char_uppercase') });
        
        return (
            <RegistrationContainer className="RegistrationContainer">
                <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="center" NoMargin Text={t('Register')} TextAlign="center" />

                <Spacer Size="medium" />

                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('Register_info')} TextAlign="center" />

                <Spacer Size="medium" />

                <LanguageOptions Language={LanguageId} OnChangeLanguageId={this.onChangeLanguageId} />

                <UserDetailsContainer className="UserDetailsContainer">
                    <UserDetailsItemContainer className="UserDetailsItemContainer">
                        <StyledAccountInput
                            FontSize="medium-3"
                            Label={t('username')}
                            OnBlur={this.onBlurUserName}
                            OnChange={event => this.onChangeRegistrationInput({ InputName: 'UserName', InputValue: event.target.value })}
                            Placeholder={t('username')}
                            Size="large"
                            Type="text"
                            Value={UserName}
                        />

                        {!CheckingUserName && UserNameTaken && <PageText FontColor="red-bittersweet" FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin Text={t('username_taken')} TextAlign="left" />}

                        {
                            CheckingUserName &&
                            <CheckingUserNameContainer className="CheckingUserNameContainer">
                                <i className="fas fa-spinner fa-spin"></i>

                                <PageText FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin Text={t('checking_username')} TextAlign="left" />
                            </CheckingUserNameContainer>
                        }
                    </UserDetailsItemContainer>

                    <UserDetailsItemContainer className="UserDetailsItemContainer">
                        <StyledAccountInput
                            FontSize="medium-3"
                            Label={t('birthday')}
                            OnChange={event => this.onChangeRegistrationInput({ InputName: 'DOB', InputValue: event.target.value })}
                            Size="large"
                            Type="date"
                            Value={DOB}
                        />
                    </UserDetailsItemContainer>

                    <UserDetailsItemContainer className="UserDetailsItemContainer">
                        <PageText FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin Text={t('sex')} TextAlign="left" />

                        <StyledSexButtonGroups
                            Buttons={Buttons}
                            ButtonWidth="fit-content"
                            NotTouching
                            OwnRows={Device === 'ipad'}
                        />
                    </UserDetailsItemContainer>

                    <UserDetailsItemContainer className="UserDetailsItemContainer">
                        <PageText FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin Text={t('Name')} TextAlign="left" />

                        <PageText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin Text={t('first_name')} TextAlign="left" />
                        
                        <StyledAccountInput
                            FontSize="medium-3"
                            NoLabel
                            OnChange={event => this.onChangeRegistrationInput({ InputName: 'FirstName', InputValue: event.target.value })}
                            Placeholder={t('first_name')}
                            Size="large"
                            Type="text"
                            Value={FirstName}
                        />

                        <PageText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin Text={t('last_name')} TextAlign="left" />
                        <StyledAccountInput
                            FontSize="medium-3"
                            NoLabel
                            OnChange={event => this.onChangeRegistrationInput({ InputName: 'LastName', InputValue: event.target.value })}
                            Placeholder={t('last_name')}
                            Size="large"
                            Type="text"
                            Value={LastName}
                        />
                    </UserDetailsItemContainer>
                </UserDetailsContainer>

                <Spacer Size="medium" />

                <UserDetailsItemContainer>
                    <PageText FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin Text={t('password_with_first_char_uppercase')} TextAlign="left" />

                    <StyledAccountInput
                        FontSize="medium-3"
                        MaxWidth="300px"
                        NoLabel
                        OnChange={event => this.onChangePasswordInput({ InputType: 'Password', InputValue: event.target.value })}
                        Placeholder={t('Profile_new_password')}
                        Size="large"
                        Type={ShowPasswords ? 'text' : 'password'}
                        Value={Password}
                    />

                    <StyledAccountInput
                        FontSize="medium-3"
                        MaxWidth="300px"
                        NoLabel
                        OnChange={event => this.onChangePasswordInput({ InputType: 'ConfirmPassword', InputValue: event.target.value })}
                        Placeholder={t('confirm_password_with_first_char_uppercase')}
                        Size="large"
                        Type={ShowPasswords ? 'text' : 'password'}
                        Value={ConfirmPassword}
                    />

                    {Password && ConfirmPassword !== Password && <PageText FontColor="red-bittersweet" FontFamily="semibold" FontSize="small" JustifyContent="flex-start" Text={t('Alert_invalid_doesnot_match_password')} TextAlign="left" />}

                    {this.renderShowPasswords()}
                </UserDetailsItemContainer>

                {
                    this.props.TryingRegisterUserError &&
                    <>
                        <Spacer Size="medium" />

                        <PageText FontColor="red-bittersweet" FontFamily="medium" FontSize="medium-2" JustifyContent="center" NoMargin Text={this.props.TryingRegisterUserError} TextAlign="center" />
                    </>
                }
            </RegistrationContainer>
        );
    }

    renderRegistrationButton = () => {
        var { t } = this.props;
        var { ConfirmPassword, DOB, FirstName, Gender, LastName, Password, UserName, UserNameTaken } = this.state.RegistrationFields;

        var Disabled = this.props.TryingRegisterUser || !DOB || !FirstName || !Gender || !LastName || !UserName || UserNameTaken || !Password || (ConfirmPassword !== Password);

        var Buttons = [{ BackgroundColor: 'abel-blue', BackgroundColorHover: 'abel-blue-hover', Border: 'none', Color: 'white', ColorHover: 'white', Disabled, FontFamily: 'semibold', OnClick: this.onRegisterUser, Title: t('Register') }];

        return <BackNextButtons Buttons={Buttons} />;
    }

    renderShowCancelContractModal = () => {
        var { t } = this.props;
        var { ShowCancelContractModal } = this.state;
    
        if (ShowCancelContractModal) {
            return (
                <Info
                    BottomButton="submit"
                    BottomButtonOnClick={this.onRejectContract}
                    BottomButtonText={t('cancel_contract')}
                    Information={t('cancel_contract_warning')}
                    OnHide={() => this.onToggleShowCancelContractModal(false)}
                    Show={ShowCancelContractModal}
                />
            );
        }
    }

    renderShowPasswords = () => {
        var { t } = this.props;
        var { HoverShowPasswords, ShowPasswords } = this.state.RegistrationFields;

        return (
            <ShowPasswordsContainer onMouseEnter={this.onToggleHoverShowPasswords} onMouseLeave={this.onToggleHoverShowPasswords}>
                <CircleDiv onClick={this.onToggleShowPasswords} Selected={HoverShowPasswords || ShowPasswords} />
                <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin OnClick={this.onToggleShowPasswords} Text={t('show_passwords')} TextAlign="left" />
            </ShowPasswordsContainer>
        );
    }

    renderSignContract = () => {
        return (
            <>
                {this.renderQuestionCount()}

                <Spacer Size="medium" />

                <QuestionContentContainer className="QuestionContentContainer">
                    {this.renderQuestionScreen()}
                </QuestionContentContainer>
            </>
        );
    }

    renderSummaryScreen = () => {
        var { t } = this.props;
        var { HealthDeclarations } = this.state.ContractAnswers;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="center" NoMargin Text={t('Summary')} TextAlign="center" />

                <Spacer Size="medium" />

                {!!HealthDeclarations.length && this.renderHealthDeclarations(HealthDeclarations)}
            </>
        );
    }

    renderTermsConditionsScreen = TermsConditions => {
        if (!TermsConditions) TermsConditions = this.state.TermsConditions;
        
        return <TermsConditionsContainer>{TermsConditions}</TermsConditionsContainer>;
    }

    renderTrainerOrganizations = () => {
        var { t } = this.props;
        var { ContractDetails: { OrganizationName: ChosenOrgName, Trainer }, TrainerOrganizations } = this.props.ContractDetail;

        var OrgNames = TrainerOrganizations.map(({ OrganizationName }) => OrganizationName).join(', ');

        return (
            <TrainerOrganizationsContainer className="TrainerOrganizationsContainer">
                <PageText FontFamily="semibold" FontSize="medium-2" JustifyContent="center" NoMargin Text={`${t('trainer_is_coach_in_these_orgs', { TrainerName: Trainer })}:`} TextAlign="center" />

                <Spacer Size="extra-small" />

                {
                    TrainerOrganizations.map(({ OrganizationName }, TOIndex) => {
                        return (
                            <TrainerOrganizationContainer className="TrainerOrganizationContainer" key={TOIndex}>
                                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={OrganizationName} TextAlign="center" />
                            </TrainerOrganizationContainer>
                        );
                    })
                }

                {
                    !!ChosenOrgName &&
                    <>
                        <Spacer Size="medium" />

                        <PageText FontFamily="semibold" FontSize="medium-2" JustifyContent="center" NoMargin Text={`${t('contract_made_collaboration_with')}:`} TextAlign="center" />
                        
                        <Spacer Size="extra-small" />

                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={ChosenOrgName} TextAlign="center" />
                    </>
                }

                <Spacer Size="medium" />

                <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('sign_contract_all_orgs_can_see', { OrgNames, TrainerName: Trainer })} TextAlign="center" />
            </TrainerOrganizationsContainer>
        );
    }

    renderTrainerRequests = () => {
        var { t } = this.props;
        var { TrainerRequests, TryingRespondToTrainerRequest } = this.props;

        // Can Only Have Maximum Of 1 Request
        var { DisplayName, TrainerId, TrainerProfilePhotoUrl, TrainerSex } = TrainerRequests[0];

        var Texts = [
            { FontFamily: 'semibold', FontSize: 'medium-1', Text: DisplayName },
            { FontFamily: 'medium', FontSize: 'medium-1', Text: t('wants_to_be_your') },
            { FontFamily: 'semibold', FontSize: 'medium-1', Text: t('coach') },
            { FontFamily: 'medium', FontSize: 'medium-1', Text: `${t('in_abel')}.` }
        ];

        var Disabled = !!TryingRespondToTrainerRequest;

        return (
            <TrainerRequestsContainer className="TrainerRequestsContainer">
                <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="center" NoMargin Text={t('coach_request')} TextAlign="center" />

                <Spacer Size="medium" />

                <TrainerRequestContainer className="TrainerRequestContainer">
                    <StyledProfilePhoto Sex={TrainerSex} ShowOverlay={false} Size="large-3" Source={TrainerProfilePhotoUrl} />

                    <Spacer Size="medium" />

                    <MultiText Texts={Texts} />

                    <Spacer Size="small" />

                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('is_that_ok')} TextAlign="center" />

                    <Spacer Size="small" />

                    <ButtonGroup
                        Buttons={[
                            { BackgroundColor: 'red-bittersweet', BackgroundColorHover: 'red-bittersweet-hover', Border: 'none', Color: 'white', ColorHover: 'white', Disabled, FontFamily: 'semibold', OnClick: () => this.onRespondTrainerRequest({ RequestType: 'Block', TrainerId }), Title: t('no_with_first_char_uppercase') },
                            { BackgroundColor: 'abel-blue', BackgroundColorHover: 'abel-blue-hover', Border: 'none', Color: 'white', ColorHover: 'white', Disabled, FontFamily: 'semibold', OnClick: () => this.onRespondTrainerRequest({ RequestType: 'Accept', TrainerId }), Title: t('yes_with_first_char_uppercase') }
                        ]}
                        ButtonWidth="100px"
                        NotTouching
                    />
                </TrainerRequestContainer>
            </TrainerRequestsContainer>
        );
    }

    render() {
        if (this.state.Loading || this.props.TryingViewContractDetail) return <Loading />;

        var { ContractDetail: { IsCancelled, IsRejected, UserSigned }, TrainerRequests, TryingAcceptContract, TryingInitiateInvoicePayment, TryingRejectContract, TryingRegisterUser, TryingRespondToTrainerRequest } = this.props;
        var { contractSigned, IsPreRegistered } = this.state;

        return (
            <>
                {(TryingAcceptContract || TryingInitiateInvoicePayment || TryingRejectContract || TryingRegisterUser || TryingRespondToTrainerRequest) && <Loading />}

                <Page renderSubmitButton={(!!+IsCancelled || !!+IsRejected || UserSigned || contractSigned) ? null : IsPreRegistered ? this.renderRegistrationButton : !!TrainerRequests.length ? null : this.renderMultiButton}>
                    {
                        (!!+IsCancelled || !!+IsRejected || UserSigned || contractSigned) ? this.renderContractPreview()
                    :
                        IsPreRegistered ? this.renderRegistration()
                    :
                        !!TrainerRequests.length ? this.renderTrainerRequests()
                    :
                        this.renderSignContract()
                    }
                </Page>

                {this.renderShowCancelContractModal()}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,

        ContractDetail: state.Contract.ContractDetail,

        Cities: state.Auth.Cities,
        Countries: state.Auth.Countries,
        IsPreRegistered: state.Auth.IsPreRegistered,
        States: state.Auth.States,
        TrainerRequests: state.Auth.TrainerRequests,

        TryingGetCities: state.Auth.TryingGetCities,
        TryingGetCitiesError: state.Auth.TryingGetCitiesError,
        TryingGetCountries: state.Auth.TryingGetCountries,
        TryingGetCountriesError: state.Auth.TryingGetCountriesError,
        TryingGetStates: state.Auth.TryingGetStates,
        TryingGetStatesError: state.Auth.TryingGetStatesError,
        TryingRegisterUser: state.Auth.TryingRegisterUser,
        TryingRegisterUserError: state.Auth.TryingRegisterUserError,

        TryingAcceptContract: state.Contract.TryingAcceptContract,
        TryingAcceptContractError: state.Contract.TryingAcceptContractError,
        TryingRejectContract: state.Contract.TryingRejectContract,
        TryingRejectContractError: state.Contract.TryingRejectContractError,
        TryingRespondToTrainerRequest: state.Contract.TryingRespondToTrainerRequest,
        TryingRespondToTrainerRequestError: state.Contract.TryingRespondToTrainerRequestError,
        TryingViewContractDetail: state.Contract.TryingViewContractDetail,
        TryingViewContractDetailError: state.Contract.TryingViewContractDetailError,

        TryingInitiateInvoicePayment: state.Invoice.TryingInitiateInvoicePayment,
        TryingInitiateInvoicePaymentError: state.Invoice.TryingInitiateInvoicePaymentError,
    };
};

export default withTranslation()(connect(mapStateToProps, { AcceptContract, EditUserProfile, InitiateSubscriptionAcceptancePayment, RegisterUser, RejectContract, RespondToTrainerRequest, SearchGoogleMapsAutocomplete, SearchGoogleMapsPlaces, ValidateUser, ViewContractDetail } )(Contract));